var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:""},[_c('titleBar',{attrs:{"title":" Images","back":false,"showBackArrowDesktop":true,"subtitle":"","centered":false,"theme":"white","showSignup":false,"containerClass":"donotuse","showDesktop":true,"showAfterScroll":0,"scrollTopOnClick":true,"inline":false,"actionButtonStyle":"small","actionButtonRoundBg":"transparent","iconPack":"far","isHome":false,"actions":[
      {
        icon: 'search',
        to: '/explore',
        hidden: false,
        hiddenTablet: false,
      },
      {
        icon: 'bell',
        to: '/notifications',
        hidden: false,
        hiddenTablet: false,
      },
      /*  {
        icon: 'comment',
        to: '/messages',
        hidden: false,
        hiddenTablet: false,
      },*/
    ]}}),_vm._v(" "),_c('div',{staticClass:"section"},[_c('exploreTabs',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}]}),_vm._v(" "),_vm._l((_vm.imageTags),function(tag,index){return _c('div',{key:index,staticClass:"tag button tag1 is-warningNO",class:{
        'is-info': _vm.$route.params.tag == tag,
      },on:{"click":function($event){return _vm.clickTag(tag)}}},[_vm._v("\n      "+_vm._s(tag)+"\n    ")])}),_vm._v(" "),_c('imageSearchResults',{ref:"imageSearchResults",attrs:{"images":_vm.images}}),_vm._v(" "),_c('div',{staticClass:"section"},[(!_vm.loadingProj)?_c('div',{staticClass:"container home"}):_vm._e()])],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
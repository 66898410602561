<template>
  <div>
    <!--
<br>
commmm232131 > {{id}}
<hr> 
-->

    <h3 class="title -3" v-if="!hideCount">
      <span v-if="nbComments == 1">1 comment </span>
      <span v-if="nbComments > 1">{{ nbComments }} comments </span>
    </h3>

    <form
      @submit.prevent="submitForm"
      class="comment-box"
      style="display: block"
      action="/ndaaa-new-comment"
      method="post"
    >
      <h5 v-if="!hideCta" class="title k is-3" style="margin-bottom: 0.1em">
        <span v-if="nbComments > 0">Add a new comment </span>
        <span v-else>
          {{ headingNoComments ? headingNoComments : "Start the discussion" }}
        </span>
      </h5>

      <div class="columns is-mobile is-variable is-1" style="margin: 0">
        <div class="column is-narrow" v-if="this.$store.main.getters.isLoggedIn">
          <router-link :to="this.$store.main.getters.profileUrl">
            <img
              :src="this.$store.main.getters.profileAvatar"
              class="squircle avatar"
              style="width: 50px; max-width: 50px; height: 50px"
            />
          </router-link>
        </div>
        <!--
      {{$store.main.getters.isLoggedIn}} = $store.main.getters.isLoggedIn
    -->
        <div class="column">
          <textarea-autosize
            :disabled="!$store.main.getters.isLoggedIn"
            :min-height="10"
            :max-height="250"
            required=""
            :placeholder="'Say something nice...' + (authorDisplayName || '')"
            class="input"
            v-model="commentText"
            name="commentText"
            style="border-radius: 10px; background: #fafafa; margin-bottom: 10px"
          ></textarea-autosize>
          <button type="submit" class="button is-dark is-small is-rounded">
            <span v-if="$store.main.getters.isLoggedIn"> Post comment </span>
            <span v-else> Login to comment </span>
          </button>
        </div>
      </div>

      <input type="hidden" value="" name="replyTo" />
    </form>

    <div v-for="(i, index) in comments" :key="index + 'fds8'">
      <div class="commentSmall">
        <div class="columns is-mobile is-vcenteredNOT">
          <div class="column is-narrow">
            <router-link :to="i.authorUrl" class="  ">
              <img :src="i.authorAvatar" class="avatar" style="display: inline-block; width: 2rem" loading="lazy" />
            </router-link>
          </div>

          <div class="column">
            <router-link :to="i.authorUrl" class="  ">
              <h6 class=" ">
                {{ i.author }}
                <span v-if="i.author == authorShortid"> 🎤 </span>
              </h6>
            </router-link>
            <p style="padding-top: 0px; white-space: pre-line">{{ i.text }}</p>

            <!--
m,obile only upvote btn version
-->
            <b-button
              class="is-hidden-tablet"
              icon-left="chevron-up"
              icon-pack="fas"
              type="is-dark  "
              rounded
              outlined
              size="is-small"
              @click="upvoteComment(i.id)"
              style="margin-right: 10px"
            >
              <span>{{ i.upvotesCount }} </span>
            </b-button>

            <small class="muted small" style="opacity: 0.5">
              <timeago :datetime="new Date(i.created)" :auto-update="0"></timeago>
              <!--
              {{ new Date(i.added) | dateFormat('MMM D, YYYY') }}-->
            </small>

            <!--
<code>{{i._id}}</code>
-->
          </div>
          <!--
          <div class="column is-narrow"></div>
          -->
          <div class="column is-narrow">
            <b-button
              v-if="$store.main.getters.isAdmin || $store.main.getters.userId == i.shortid"
              icon-left="trash"
              icon-pack="fas"
              rounded
              type="is-danger is-light "
              outlined
              size="is-small"
              @click="removeComment(i._id)"
            >
              <span> </span>
            </b-button>
          </div>
          <div class="column is-narrow is-hidden-mobile">
            <b-button
              icon-left="chevron-up"
              icon-pack="fas"
              type="is-dark  "
              rounded
              outlined
              size="is-small"
              @click="upvoteComment(i._id)"
            >
              <span>{{ i.upvotesCount }} </span>
            </b-button>

            <!--  <a
              href="/profile/api/upvote/postComment/602f4831ded10f053215ddc1"
              class="button is-rounded is-largeNO is-small is-outlined is-dark "
              style="margin-right:8px;"
            >
              <span class="icon is-medium">
                <svg
                  class="svg-inline--fa fa-chevron-up fa-w-14"
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="chevron-up"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                  data-fa-i2svg=""
                >
                  <path
                    fill="currentColor"
                    d="M240.971 130.524l194.343 194.343c9.373 9.373 9.373 24.569 0 33.941l-22.667 22.667c-9.357 9.357-24.522 9.375-33.901.04L224 227.495 69.255 381.516c-9.379 9.335-24.544 9.317-33.901-.04l-22.667-22.667c-9.373-9.373-9.373-24.569 0-33.941L207.03 130.525c9.372-9.373 24.568-9.373 33.941-.001z"
                  ></path></svg
                >
              </span>
              <span>0</span>
            </a> -->
          </div>
        </div>
      </div>
      <div class="spacer m" v-if="index != comments.length - 1" />

      <!--  <hr v-if="index != comments.length - 1" />
     -->
    </div>
    <!-- eo loop-->
    <div class="spacer m" />

    <jsonDebug :data="comments" label=" view comments  " />
  </div>
</template>

<script>
/*
{
     "_id": "6037c243051d1700e15f3e68",
     "entity": "discussion",
     "postId": "60375fa7e3c78a00d583f2c0",
     "replyTo": "",
     "commentText": "Good thoughts on the programs. I'm a fan of programs like rollercoin, betfury and trustdice where is some sort of backend mining aspect where you are earning all the time on some idle level, and teaching your refs to do the same. The one time payments are nice, but long term commission are at the heart of our philosophy on my site and programs i choose.\r\n\r\nGet them refs man! Best of luck to you. \r\n\r\nIf you haven't heard of \r\nRollercoin\r\nBetfury or \r\nTrustdice\r\n\r\nplease feel free to hit me up with questions. \r\n\r\nI'm usually there upping my mining tokens, with the money they give me.\r\n\r\n(paradice is another fun one, but no dividend program, lots of free crypto in rain though.)\r\n\r\nLet me show you how.",
     "name": "I Want Free Crypto",
     "avatar": "https://lh5.googleusercontent.com/-xUtDHrwonLw/AAAAAAAAAAI/AAAAAAAAAAA/AMZuuckOiuBGVfSM9b5uJbGkQPAwgirhTg/s96-c/photo.jpg",
     "url": "/@iwantfreecrypto",
     "email": "iwantfreecrypto@gmail.com",
     "shortId": "IQZPWsly7",
     "shortid": "IQZPWsly7",
     "handle": "iwantfreecrypto",
     "added": "2021-02-25T15:29:07.707Z"
 }
*/

//var tt = ExtractASIN('http://www.amazon.com/Kindle-Wireless-Reading-Display-Generation/dp/B0015T963C/ref=amb_link_86123711_2?pf_rd_m=ATVPDKIKX0DER&pf_rd_s=center-1&pf_rd_r=0AY9N5GXRYHCADJP5P0V&pf_rd_t=101&pf_rd_p=500528151&pf_rd_i=507846');
//alert(tt);
//import Layout from '@/components/layout/LayoutDash.vue';
//import NewPostMenuItems from '@/components/nav/newPostMenuItems';
import jsonDebug from "@/components/e/jsonDebug";
//import TextareaAutosize from "vue-textarea-autosize";

export default {
  // name: "Home",
  components: {
    jsonDebug,
    // TextareaAutosize,
    //  Layout,  //NewPostMenuItems
  },
  data() {
    return {
      comments: [],
      sending: false,
      commentText: "",
      //id:'' //default
    };
  },
  props: ["id", "headingNoComments", "authorShortid", "entity", "authorDisplayName", "hideCta", "hideCount"],
  mounted() {
    this.loadMain();
  },
  updated() {
    //fire on route change - all of em!
    //this.loadMain()
  },
  computed: {
    nbComments: function () {
      return this.comments.length || 0;
    },
  },
  watch: {
    id: function (from, to) {
      //necessary to refresh on url changes.
      this.loadMain();
    },
  },
  methods: {
    loadMain(opt) {
      var apiOptions = opt || { noCache: 1 };
      this.comments = [];
      this.loading = true;
      //alert( this.id)
      if (!this.id) return false;
      window.API.getPostComments(this.id, apiOptions).then((res) => {
        console.log(res);
        this.comments = res;
        this.loading = false;
        this.$emit("commentCountUpdate", this.comments.length);
      });
    },
    deletePostComment(id) {
      window.API.removeComment(id).then((res) => {
        console.log(res, "DONE deleting....");
        //this.comments = res
        //  this.loading = false;
        this.loadMain({ noServerCache: 1 });
      });
    },
    upvoteComment(id) {
      window.API.upvoteComment(id).then((res) => {
        console.log(res, "DONE deleting....");
        //this.comments = res
        //  this.loading = false;
        this.loadMain({ noServerCache: 1 });
        this.$buefy.toast.open({
          message: "Upvoted! ",
          type: "is-success",
        });
      });
    },
    async submitForm() {
      var me = this;
      this.$store.main.commit("ensureLogged", "To comment on this post, please create an account.");
      //alert(isLogged);
      //if(!isLogged) return false;
      this.sending = true;
      window.API.addPostComment(this.id, {
        text: this.commentText,
        postId: this.id,
        entity: this.entity,
      })
        .then((res) => {
          me.commentText = "";
          console.log(res);
          this.sending = false;
          this.loadMain({ noServerCache: 1 }); //refresh comment list.

          //show toast THANK YOU COMMENTING :)
        })
        // .then(() => this.loadMain() ) //trigger refresh properly? We might need to bust cache...
        .catch((err) => console.log(err));
    },
  }, //eo methods
};
</script>
<style scoped>
.code {
  background-color: #444;
  color: #f5d67b;
  border-radius: 20px;
  line-height: 1.4em;
  font-family: Monaco, Menlo, MyFancyCustomFont, monospace, mono;
}
</style>

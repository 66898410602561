<template>
  <div class=" ">
    <titleBar
      title=" Images"
      :back="false"
      :showBackArrowDesktop="true"
      subtitle=""
      :centered="false"
      theme="white"
      :showSignup="false"
      containerClass="donotuse"
      :showDesktop="true"
      :showAfterScroll="0"
      :scrollTopOnClick="true"
      :inline="false"
      actionButtonStyle="small"
      actionButtonRoundBg="transparent"
      iconPack="far"
      :isHome="false"
      :actions="[
        {
          icon: 'search',
          to: '/explore',
          hidden: false,
          hiddenTablet: false,
        },
        {
          icon: 'bell',
          to: '/notifications',
          hidden: false,
          hiddenTablet: false,
        },
        /*  {
          icon: 'comment',
          to: '/messages',
          hidden: false,
          hiddenTablet: false,
        },*/
      ]"
    />

    <div class="section">
      <exploreTabs v-show="false" />

      <div
        v-for="(tag, index) in imageTags"
        :key="index"
        class="tag button tag1 is-warningNO"
        :class="{
          'is-info': $route.params.tag == tag,
        }"
        @click="clickTag(tag)"
      >
        {{ tag }}
      </div>

      <imageSearchResults :images="images" ref="imageSearchResults" />

      <div class="section">
        <div class="container home" v-if="!loadingProj">
          <!-- 
        <div class="columns is-multiline">
          <div class="column is-3-desktop is-12-tablet">
            <h1 class="title is-3">Your APIs</h1>
            <h1 class="subtitle is-5">Plan, prototype and manage API projects.</h1>
          

            <router-link :to="{ path: $store.main.state.userToken ? '/new' : '/login' }" active-class="is-active" exact>
              <b-button rounded>Add a new API</b-button>
            </router-link>
          </div>
          <div class="column">
            <div class="columns is-multiline">
              <div class="column is-6-tablet is-6-desktop is-4" v-for="i in projs" :key="i.id">
              
                <router-link :to="{ path: '/' + i.id }" active-class="is-active" exact>
                  <projCard :proj="i" />
                </router-link>
              </div>
            </div>
          </div>
        </div>

        <router-link :to="{ path: '/explore' }">
          <b-button type="is-info is-outlined">Explore Public examples</b-button>
        </router-link>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";
import FeaturedBots from "@/components/FeaturedBots.vue";
import botsTabs from "@/components/nav/botsTabs.vue";
import exploreTabs from "@/components/nav/exploreTabs.vue";
import imageSearchResults from "@/components/search/imageSearchResults.vue";

const imageTags = [
  "popular",
  "cat",
  "dog",
  "beach",
  "forest",
  "city",
  "mountain",
  "sunset",
  "flower",
  "car",
  "bird",
  "food",
  "ocean",
  "desert",
  "winter",
  "summer",
  "people",
  "night",
  "rain",
  "party",
  "garden",
  "home",
  "unicorn painting",
  "lake",
  "park",
  "road",
  "toy",
  "game",
  "sky",
  "tree",
  "moon",
  "sun",
  "river",
  "farm",
  "jungle",
  "animal",
  "book",
  "music",
  "sport",
  "plane",
  "island",
  "street",
  "building",
  "snow",
  "rainbow",
  "birthday",
  "school",
  "family",
  "travel",
  "stars",

  /*
  "surreal",
  "vibrant",
  "ethereal",
  "retro",
  "minimalist",
  "futuristic",
  "abstract",
  "vintage",
  "cosmic",
  "aerial",
  "monochrome",
  "bioluminescent",
  "kaleidoscopic",
  "rustic",
  "cyberpunk",
  "tropical",
  "metropolitan",
  "wilderness",
  "neon",
  "underwater",
  "wildlife",
  "portrait",
  "landscape",
  "macro",
  "architectural",
  "night",
  "street",
  "festival",
  "oceanic",
  "mountainous",
  "urban",
  "desert",
  "forest",
  "seascape",
  "automotive",
  "culinary",
  "fauna",
  "floral",
  "fashion",
  "sports",
  "celestial",
  "nautical",
  "historical",
  "candid",
  "adventure",
  "rainforest",
  "glacial",
  "beach",
  "rural",
  "industrial",
  */
];

export default {
  name: "Home",
  components: {
    //  FeaturedBots,
    //botsTabs,
    exploreTabs,
    imageSearchResults,
    //  HelloWorld,
    //   projCard,
  },
  data() {
    return {
      imageTags,
      projs: [],
      loadingProj: true,
      loading: true,
      publi: [],
      images: [],

      // loadingOps: true,
    };
  },
  watch: {
    "$route.params.tag": function (to, from) {
      console.log("ROUTE CHANGED", to, from);
      this.load();
    },
  },
  methods: {
    load() {
      var t = this.$route.params.tag || "";
      this.getImages(t);
    },
    getImages(tag) {
      var q = tag || "";
      if (q == "popular") q = "";
      if (!q) {
        window.API.getGlobalPostFeed({
          search: q,
          explore: true,
        }).then((myData) => {
          this.images = myData;
          this.loading = false;
          // code to display myData
        });
      } else {
        window.API.searchImages({
          search: q,
          explore: true,
        }).then((myData) => {
          this.images = myData;
          this.loading = false;
          // code to display myData
        });
      }
    },

    clickTag(tag) {
      const basePath = this.$route.path.split("?")[0]; // This gets the current base path without any query params
      const u = `${basePath}?tag=${tag}`;
      this.$router.replace({ name: "ExploreImagesByTag", params: { tag: tag } });
    },

    async performSearch() {
      this.isLoading = true;
      var q = this.search;
      //  alert(3);
      // perform your search here, then update results and isLoading accordingly
      // the following is a placeholder and should be replaced with your own search code

      //redirect to search page
      //withj ?q=serch
    },
  },
  mounted() {
    this.load();
    /*
    window.API.getPublicProjects().then((proj) => {
      this.publi = proj;
      this.loadingProjPubli = false;
      // console.log("PROJJ!", proj, proj.id);
    });*/

    var u = this.$store.main.state.userId;

    //  this.projs = proj;
    this.loadingProj = false;

    /*
    window.API.getUserProjects(u).then((proj) => {
      this.projs = proj;
      this.loadingProj = false;
      // console.log("PROJJ!", proj, proj.id);
    });*/
    /*
    window.API.getProjectOperations(this.$route.params.project).then((ops) => {
      var opId = this.$route.params.operation;
      this.ops = ops;
      this.loadingOps = false;
      this.op = ops.filter((i) => i.operationId == opId)[0]; //TODO: validaiton
    });*/
  },
};
</script>
<style scoped>
.tag1 {
  margin-right: 5px;
  margin-bottom: 4px;
  text-transform: uppercase;
}
</style>
